import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'regexMatch',
  standalone: true,
})
export class RegexMatchPipe implements PipeTransform {
  transform(value: string, pattern: string): boolean {
    const regex = new RegExp(pattern);
    return regex.test(value);
  }
}
