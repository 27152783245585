import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  Category,
  CategoryPreview,
  CategoryPreviewApiResponse,
  CreateCategoryRequestParams,
  CreateCategoryResponse,
} from 'src/models/category.model';
import { ApiDataResponseMessage, ApiDataResponseObject } from '@root/models/global-interfaces';

@Injectable()
export class CategoryService {
  private readonly http = inject(HttpClient);
  private apiUrl = environment.apiUrl;

  createCategory(body: CreateCategoryRequestParams) {
    return this.http.post<CreateCategoryResponse>(`${this.apiUrl}topics`, body);
  }

  getCategories() {
    return this.http.get<CategoryPreview[]>(`${this.apiUrl}topics`);
  }

  updateCategories(categoryArray: CategoryPreviewApiResponse[]) {
    return this.http.post<Category>(`${this.apiUrl}topics/bulk-update`, { categoryArray });
  }

  deleteCategory(categoryID: number) {
    return this.http
      .delete<ApiDataResponseObject<ApiDataResponseMessage>>(`${this.apiUrl}topics/${categoryID}`)
      .pipe(map(res => res.data!));
  }

  searchCategories(categories: Category[], searchTerm: string, expand = true): Category[] {
    const lowerCaseSearchTerm: string = searchTerm.toLowerCase();
    const filteredCategories: Category[] = categories.filter(cat => {
      const normalizedTitle: string = cat.topicTitle.toLowerCase();
      return normalizedTitle.includes(lowerCaseSearchTerm);
    });

    if (searchTerm.length === 0) {
      return filteredCategories;
    }

    const outputTree: Category[] = [];

    filteredCategories.forEach((category: Category) => {
      let parent = categories.find(cat => cat.topicID === category.topicParentID);

      const exists = outputTree.find(cat => cat.topicID === category.topicID);
      while (parent) {
        const parentExists = outputTree.find(cat => cat.topicID === parent?.topicID);
        if (!parentExists) {
          outputTree.push(parent);
        }
        if (expand) {
          parent.isExpanded = true;
        }
        parent = categories.find(cat => cat.topicID === parent?.topicParentID);
      }
      if (!exists) {
        outputTree.push(category);
      }
    });

    return outputTree;
  }
}
