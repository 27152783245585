import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  effect,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { JwtAuthService } from '@services/auth/jwt-auth.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ChangePasswordDialog } from '@shared/dialogs/change-password/change-password.dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { NotificationSettingDialog } from '@shared/dialogs/notification-setting/notification-setting.dialog';
import { DialogService } from 'src/services/dialog.service';
import { MatDividerModule } from '@angular/material/divider';
import { GeneralStore } from '@root/store/general.store';
import { UserInfo } from '@root/models/user.model';
import { Bell, Lock, LogOut, LucideAngularModule, User } from 'lucide-angular';
import { LucideIconData } from 'lucide-angular/icons/types';
import { NewActionButtonComponent } from '../new-action-button/new-action-button.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatMenuModule,
    MatToolbarModule,
    MatDialogModule,
    MatDividerModule,
    NgOptimizedImage,
    LucideAngularModule,
    NewActionButtonComponent,
  ],
})
export class HeaderComponent {
  private readonly authService = inject(JwtAuthService);
  private readonly router = inject(Router);
  private readonly dialogService = inject(DialogService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  protected readonly generalStore = inject(GeneralStore);

  protected readonly User: LucideIconData = User;
  protected readonly Lock: LucideIconData = Lock;
  protected readonly Bell: LucideIconData = Bell;
  protected readonly LogOut: LucideIconData = LogOut;
  @Input() showLogo = false;
  protected currentUser: UserInfo | null;
  protected userFullName = '';
  protected customerName = '';

  @Output() expandSidebar: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    effect(() => {
      this.currentUser = this.generalStore.userInfo();
      if (this.currentUser) {
        if (this.currentUser.userFullName) {
          this.userFullName = this.currentUser.userFullName;
        }
        if (this.currentUser.customer?.customerName) {
          this.customerName = this.currentUser.customer.customerName;
        }
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  protected openChangePasswordModal(): void {
    this.dialogService.open<ChangePasswordDialog, null>({
      component: ChangePasswordDialog,
      width: '530px',
    });
  }

  protected logout() {
    this.authService.logout(() => {
      this.generalStore.reset();
      this.router.navigate(['/login']);
    });
  }

  protected openNotificationSettingsModal(): void {
    this.dialogService.open<NotificationSettingDialog, null>({
      component: NotificationSettingDialog,
      width: '608px',
    });
  }
}
