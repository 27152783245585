import { DataScope } from './global-enum';
import { ApiRequestParams, ApiResponse, defaultApiRequestParams } from './global-interfaces';
import { User } from './user.model';

export const rolesTableApiRequestParams: ApiRequestParams = {
  ...defaultApiRequestParams,
  dataScope: DataScope.RoleTable,
  locationID: 0,
};

// Database table: profile
// We use the term "role" in the UI, but the database table is called "Profile"
export interface Profile {
  // Database table columns
  profileID: number;
  profileParent?: number;
  profileDescription: string;
  profileDeleted: number;
  customerID: number;
  locationID?: number;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  type?: string;

  // API response only (join)
  isLocalProfile: boolean;
  isGlobalProfile: boolean;
  userCount: number;
  profile_creds: ProfileCred[];
  creds: Cred[];
  securities: Security[];
  profileUsers: User[];
  totalUsers: number;
}

export interface ProfileSummary {
  id?: number;
  isGlobalProfile: boolean;
  isLocalProfile: boolean;
  profileDescription: string;
  userFullName?: string;
  locationID?: number;
  profileID: number;
  securities: Security[];
  userCount: number;
  selected?: boolean;
  completeSelected?: boolean;
  users?: number[];
}

export interface RoleInfo {
  roleName: string;
  roleID: number;
  locationName: string;
  locationID: number;
  securityID: number;
  type: string;
  profileDescription?: string;
  profileID?: number;
}

// Database table: security
export interface Security {
  // Database table columns
  securityID: number;
  profileID: number;
  userID: number;
  locationID: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;

  // API response only (join)
  user?: User;
}

// Database table: profile_cred
export interface ProfileCred {
  // Database table columns
  profileCredID: number;
  profileCredStatus: number;
  profileID: number;
  credID: number;
  created_at: string;
  updated_at: string;
  deleted_at?: string;

  // API response only (join)
  cred: Cred;
}

// Database table: cred
export interface Cred {
  credID: number;
  credDescription: string;
  credToolTip: string;
  created_at?: string | null;
  deleted_at?: string | null;
  updated_at?: string | null;

  // API response only (join)
  pivot: CredPivot;
}

interface CredPivot {
  profileID: number;
  credID: number;
}

export interface CreateRoleResponse extends ApiResponse {
  customerID: number;
  userID: number;
  updated_at: Date;
  created_at: Date;
  profileID: number;
  profile_creds: ProfileCred[];
}

export interface CreateRoleParams {
  profileDescription: string;
  credsArray: Partial<ProfileCred>[];
  locationID?: number;
}

export enum RolePrivileges {
  None,
  Have,
}

export interface AssignRoleParams {
  locationID: number;
  profileID: number;
  userID: number;
}

export interface SingleProfileData {
  profileID: number;
  profileDescription: string;
  isLocalProfile: boolean;
  isGlobalProfile: boolean;
  userCount: number;
  securities: Security[];
  profile_creds: SingleProfileDataProfileCred[];
  creds: SingleProfileDataCred[];
}

export interface SingleProfileDataProfileCred {
  profileCredID: number;
  profileID: number;
  credID: number;
  profileCredStatus: number;
  creds?: SingleProfileDataCred[];
  cred?: SingleProfileDataCred;
  disabled?: boolean;
}

export interface SingleProfileDataCred {
  credID: number;
  credDescription: string;
  credToolTip: string;
  pivot: CredPivot;
  disabled?: boolean;
}

export interface ProfileListData {
  profileID: number;
  profileDescription: string;
  created_at: string;
  isLocalProfile: boolean;
  isGlobalProfile: boolean;
  userCount: number;
  securities: Security[];
  users?: number[];
}

export interface CredListData {
  credID: number;
  credDescription: string | null;
  credToolTip: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
