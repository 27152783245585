import { Component, effect, inject } from '@angular/core';
import { GeneralStore } from '@root/store/general.store';
import { environment } from 'src/environments/environment';
import { SSO_OPTIONS } from 'src/models/user.model';

@Component({
  selector: 'app-auth-buttons',
  templateUrl: './auth-buttons.component.html',
  styleUrls: ['./auth-buttons.component.scss'],
  standalone: true,
})
export class AuthButtonsComponent {
  protected loaded: boolean;
  protected disableSSO = false;
  protected inProgressAzure = false;
  protected inProgressOkta = false;
  private readonly generalStore = inject(GeneralStore);
  protected showOktaLogin = false;
  protected showAzureLogin = false;
  private window = window;

  constructor() {
    effect(() => {
      const ssoLogins: string[] = this.generalStore.ssoLogins();
      if (ssoLogins) {
        ssoLogins.forEach(login => {
          if (login === SSO_OPTIONS.OKTA) this.showOktaLogin = true;
          if (login === SSO_OPTIONS.AZURE) this.showAzureLogin = true;
        });
        this.loaded = true;
      }
    });
  }

  protected readonly loginWithSSO = (provider: string): void => {
    this.disableSSO = true;
    if (provider === 'azure') this.inProgressAzure = true;
    else if (provider === 'okta') this.inProgressOkta = true;
    this.window.location.href = `${environment.apiUrl}auth/${provider}/redirect`;
  };
}
