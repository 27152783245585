import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';

export interface EnvObj {
  name: string;
  production: boolean;
  apiUrl: string;
  sentry_environment: string;
  sentry_enabled: boolean;
  shouldEnableProdMode: boolean;
  shouldEnableIntercom: boolean;
}

Sentry.init({
  enabled: environment.sentry_enabled,
  dsn: 'https://c335be0af428f6116ec3489206c51abd@o4507386448052224.ingest.us.sentry.io/4507584239894528',
  environment: environment.sentry_environment,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 50% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [environment.apiUrl],
  // Session Replay
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 0.5,
  profilesSampleRate: 0.5,
});

if (environment.shouldEnableProdMode) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    providers: [provideAnimations()],
  })
  .then(() => {
    // Fix for Angular Material Drag and Drop issue (https://github.com/angular/angular/issues/45020)
    document.addEventListener('mousemove', () => {}, {
      passive: false,
      capture: true,
    });
  })
  .catch(err => console.error(err));
