import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { NewActionButtonComponent } from 'src/app/shared/components/new-action-button/new-action-button.component';
import { Subscription } from 'rxjs';
import { DialogHeaderComponent } from '@shared/components/dialog-header/dialog-header.component';
import { ChangePasswordParams } from '@models/user.model';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { UserService } from '@root/services/user-service/user.service';
import { tapResponse } from '@ngrx/operators';
import { NotificationService } from '@root/services/notification.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.dialog.html',
  styleUrls: ['./change-password.dialog.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatTableModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NewActionButtonComponent,
    DialogHeaderComponent,
    InputErrorComponent,
  ],
})
export class ChangePasswordDialog implements OnDestroy {
  protected readonly dialogRef = inject(MatDialogRef<ChangePasswordDialog>);
  private readonly userService = inject(UserService);
  private readonly notificationService = inject(NotificationService);
  protected isError = '';
  protected changePasswordForm: FormGroup;
  private errorSub: Subscription | undefined;

  // TODO: verify error handling, not sure why errors were being handled through a store
  constructor() {
    this.changePasswordForm = new FormGroup(
      {
        currentPassword: new FormControl('', Validators.required),
        password: new FormControl(''),
        repeatPassword: new FormControl('', Validators.required),
      },
      {
        validators: [this.passwordStrengthValidator(), this.passwordRepeatValidator()],
      },
    );
  }

  private passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get('password')?.value;

      /* eslint-disable @typescript-eslint/no-explicit-any */
      const currentPassword = control.get('currentPassword')?.value;

      const minPasswordLength = 8;
      const passwordRequirements: any = {
        required: /./.test(password),
        isMinLength: password.length >= minPasswordLength,
        /* eslint-disable @typescript-eslint/no-explicit-any */
        hasNumber: /\d/.test(password),
        hasSpecial: /[!@#$%^&*()_+\-=[\]{};':'\\|,.<~`>/?]/.test(password),
        notSameAsCurrent: password !== currentPassword,
      };
      const returnObject: any = {};

      Object.keys(passwordRequirements).forEach(key => {
        if (!passwordRequirements[key]) {
          returnObject[key] = true;
        }
      });

      if (Object.keys(returnObject).length) {
        control.setErrors({ passwordRequirements: returnObject });
      }

      return Object.keys(returnObject).length ? { passwordRequirements: returnObject } : null;
    };
  }

  private passwordRepeatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get('password')?.value;
      const repeatPassword = control.get('repeatPassword')?.value;

      return password === repeatPassword ? null : { passwordMismatch: true };
    };
  }

  protected getPassReq(errorName: string) {
    if (!this.changePasswordForm.errors?.['passwordRequirements']) {
      return false;
    }

    return this.changePasswordForm.errors?.['passwordRequirements'][errorName];
  }

  ngOnDestroy(): void {
    this.errorSub?.unsubscribe();
    this.isError = '';
  }

  protected onSubmit() {
    if (!this.changePasswordForm.valid) {
      Object.values(this.changePasswordForm.controls).forEach(control => {
        control.markAsTouched();
      });
    } else {
      const payload: ChangePasswordParams = {
        currentPassword: this.currentPassword?.value,
        password: this.password?.value,
      };

      this.userService
        .changePassword(payload)
        .pipe(
          tapResponse({
            next: () => {
              this.notificationService.openSuccessSnackBar('Password has been updated.');
              this.dialogRef.close();
            },
            error: () => {
              this.notificationService.openErrorSnackBar('Error changing password');
              this.changePasswordForm.reset();
            },
          }),
        )
        .subscribe();
    }
  }

  protected get currentPassword() {
    return this.changePasswordForm.get('currentPassword');
  }

  protected get password() {
    return this.changePasswordForm.get('password');
  }

  protected get repeatPassword() {
    return this.changePasswordForm.get('repeatPassword');
  }
}
