import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ApiResponse } from '../models/global-interfaces';
import { Credentials } from '@root/models/credentials.model';

export interface CreateCredentialsResponse {
  message: string;
}
@Injectable()
export class CredentialsService {
  private readonly http = inject(HttpClient);
  private apiUrl = environment.apiUrl;

  readonly createCredentials = (
    newCredentials: Credentials,
  ): Observable<CreateCredentialsResponse> => {
    return this.http.post(`${this.apiUrl}user-credentials`, newCredentials).pipe(
      map((res: CreateCredentialsResponse) => {
        return res;
      }),
    );
  };

  readonly deleteCredentials = (credentialsId: number): Observable<ApiResponse> => {
    return this.http.delete(`${this.apiUrl}user-credentials/${credentialsId}`).pipe(
      map(data => {
        return data;
      }),
    );
  };
}
