import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  NewTaskItemPayload,
  TaskItem,
  AddTaskFilesPayload,
  DuplicateTaskItem,
  AddItemOption,
  UpdateItemOption,
  BaseTaskItem,
} from '@models/task-item.model';
import { ApiDataResponseObject, ApiResponse } from '@root/models/global-interfaces';
import { LucideIconData } from 'lucide-angular/icons/types';
import {
  Calendar,
  CalendarDays,
  ChevronsRight,
  Ellipsis,
  List,
  ListChecks,
  MoveRight,
  Paperclip,
  Repeat,
  Rows3,
  Rows4,
  SquareCheck,
  Tag,
} from 'lucide-angular';

export const enum ItemTypes {
  CheckBox = 1,
  RadioList = 2,
  TextBox = 3,
  DropdownBox = 4,
  DatePicker = 5,
  Label = 7,
  Reassign = 8,
  FileAttachment = 9,
  AssignTask = 10,
  DeadlineDatePicker = 11,
  AppendToTitle = 13,
  MultiSelect = 14,
  MultiSelectDropdown = 15,
}

export const itemIcons: Map<ItemTypes, LucideIconData> = new Map([
  [ItemTypes.CheckBox, SquareCheck],
  [ItemTypes.RadioList, List],
  [ItemTypes.TextBox, Ellipsis],
  [ItemTypes.DropdownBox, Rows3],
  [ItemTypes.DatePicker, CalendarDays],
  [ItemTypes.Label, Tag],
  [ItemTypes.Reassign, Repeat],
  [ItemTypes.FileAttachment, Paperclip],
  [ItemTypes.AssignTask, MoveRight],
  [ItemTypes.DeadlineDatePicker, Calendar],
  [ItemTypes.AppendToTitle, ChevronsRight],
  [ItemTypes.MultiSelect, ListChecks],
  [ItemTypes.MultiSelectDropdown, Rows4],
]);

export interface TaskItemOrderUpdate {
  taskItemID: number;
  order: number;
  parentItemOptionID: number;
  parentTaskItemID: number;
  itemID: number;
  multiSelectOptionsParentID: number;
}

export interface Assigntask {
  linkedTaskID: number;
}
export interface TaskItemOptionOrderUpdate {
  optionID: number;
  order: number;
}

export interface TaskItemOrderBulkUpdate {
  taskItems: TaskItemOrderUpdate[];
  taskItemOptions: TaskItemOptionOrderUpdate[];
}

export type TaskItemPayload = Partial<
  Pick<
    BaseTaskItem,
    | 'itemTypeID'
    | 'parentItemOptionID'
    | 'multiSelectOptionsParentID'
    | 'prompt'
    | 'taskID'
    | 'itemID'
    | 'response'
    | 'optional'
    | 'score'
  >
  // Legacy props
> &
  Partial<{ task_id: number; item_id: number; linkedTaskID?: number }>;

export interface TaskItemResponse {
  data: TaskItem;
  message: string;
  status: string;
}

export interface SchedulePayload {
  default_time: number;
  dueAt: string;
  duration?: number;
  failedTasksEnabled: boolean;
  field1: number;
  field2: number;
  field3: string;
  field4: string;
  location_id?: number;
  name: string;
  new_tasks?: number;
  reoccurAt: string;
  startAt: string;
  taskID?: number;
  taskSetID?: number;
  time_interval: string;
  type: number;
}
@Injectable()
export class TaskItemService {
  private readonly http = inject(HttpClient);
  private apiUrl: string = environment.apiUrl;

  addTaskItem(payload: NewTaskItemPayload) {
    return this.http
      .post<ApiDataResponseObject<TaskItem>>(`${this.apiUrl}task-item`, payload)
      .pipe(map(res => res.data!));
  }
  updateTaskItem(payload: TaskItemPayload) {
    const { itemID, ...args } = payload;
    return this.http.put<ApiResponse>(`${this.apiUrl}task-item/${itemID}`, args);
  }

  updateMultiSelectTaskItem(optionId: number, payload: TaskItemPayload) {
    return this.http.put<ApiResponse>(`${this.apiUrl}multi-select-options/${optionId}`, payload);
  }

  deleteTaskItem(taskItemID: number) {
    return this.http
      .delete<ApiDataResponseObject<TaskItem>>(`${this.apiUrl}task-item/${taskItemID}`)
      .pipe(map(res => res.data!));
  }

  bulkUpdateTaskItemOrder(taskItemUpdate: TaskItemOrderBulkUpdate) {
    return this.http.put<unknown>(`${this.apiUrl}task-item/order`, taskItemUpdate);
  }

  updateMultiSelectOrder(taskItemUpdate: {
    locationId: number;
    multiSelectIDs: {
      id: number;
      order: number;
    }[];
  }) {
    return this.http.put<unknown>(`${this.apiUrl}update-multi-select-order`, taskItemUpdate);
  }

  addSchedule(reqObj: SchedulePayload) {
    return this.http
      .post<ApiDataResponseObject<SchedulePayload>>(`${this.apiUrl}schedules`, reqObj)
      .pipe(map(res => res.data!));
  }

  updateSchedule(reqObj: SchedulePayload, taskReoccurID: number) {
    return this.http
      .put<
        ApiDataResponseObject<SchedulePayload>
      >(`${this.apiUrl}task-reoccur/${taskReoccurID}`, reqObj)
      .pipe(map(res => res.data!));
  }

  deleteSchedule(taskReoccurID: number) {
    return this.http
      .delete<ApiResponse>(`${this.apiUrl}task-reoccur/${taskReoccurID}`)
      .pipe(map(res => res.data));
  }

  duplicateTaskItem(reqObj: DuplicateTaskItem) {
    return this.http
      .patch<ApiDataResponseObject<TaskItem>>(
        `${this.apiUrl}task-item-duplicate/${reqObj.itemID}`,
        {
          reqObj,
        },
      )
      .pipe(map(res => res.data!));
  }
  addOption(reqObj: AddItemOption) {
    return this.http
      .post<ApiDataResponseObject<TaskItem>>(`${this.apiUrl}task-item-option`, reqObj)
      .pipe(map(res => res.data!));
  }

  addMultiselectOption(reqObj: { task_id: number; item_id: number; prompt: string }) {
    return this.http
      .post<ApiDataResponseObject<TaskItem>>(`${this.apiUrl}multi-select-options`, reqObj)
      .pipe(map(res => res.data!));
  }
  deleteTaskItemOption(optionID: number) {
    return this.http
      .delete<ApiDataResponseObject<TaskItem>>(`${this.apiUrl}task-item-option/${optionID}`)
      .pipe(map(res => res.data!));
  }

  deleteMultiselectTaskItemOption(optionID: number, taskID: number) {
    return this.http
      .delete<
        ApiDataResponseObject<TaskItem>
      >(`${this.apiUrl}multi-select-options/${optionID}`, { body: { taskID } })
      .pipe(map(res => res.data!));
  }

  updateOption(itemData: UpdateItemOption) {
    return this.http
      .put<
        ApiDataResponseObject<TaskItem>
      >(`${this.apiUrl}task-item-option/${itemData.optionID}`, itemData)
      .pipe(map(res => res.data!));
  }

  updateMultiselectOption(itemData: UpdateItemOption) {
    return this.http
      .put<
        ApiDataResponseObject<TaskItem>
      >(`${this.apiUrl}multi-select-options/${itemData.optionID}`, itemData)
      .pipe(map(res => res.data!));
  }

  deleteTaskFile(fileID: number) {
    return this.http.delete<ApiResponse>(`${this.apiUrl}task-files/${fileID}`);
  }

  getTaskFiles(itemID: number) {
    return this.http.get<ApiResponse>(`${this.apiUrl}task-files`, { params: { itemID } });
  }

  addTaskFiles(req: AddTaskFilesPayload) {
    return this.http.post<ApiResponse>(`${this.apiUrl}task-files`, req);
  }
}
